import { useStaticQuery, graphql } from 'gatsby';

const usePageMetaAttributes = page => {
  const { allPagesImages, allPagesMetaAttributes } = useStaticQuery(allPagesMetaQuery);

  const imageKey = page in allPagesImages ? page : 'default';
  const pageImage = allPagesImages[imageKey].file.url;
  const pageImageWidth = allPagesImages[imageKey].file.details.image.width;
  const pageImageHeight = allPagesImages[imageKey].file.details.image.height;

  return {
    image: pageImage,
    imageWidth: pageImageWidth,
    imageHeight: pageImageHeight,
    jsonLdSchemas: {
      image: {
        '@context': 'schema.org',
        '@type': 'ImageObject',
        url: `https:${pageImage}`,
        width: `${pageImageWidth}`,
        height: `${pageImageHeight}`,
      },
      publisher: {
        '@context': 'schema.org',
        '@type': 'Organization',
        name: 'Namebase',
        url: 'https://namebase.io',
        logo: {
          '@context': 'schema.org',
          '@type': 'ImageObject',
          url: `https:${allPagesImages['logo'].file.url}`,
          width: allPagesImages['logo'].file.details.image.width,
          height: allPagesImages['logo'].file.details.image.height,
        },
      },
    },
    ...allPagesMetaAttributes[page],
  };
};

const allPagesMetaQuery = graphql`
  query {
    allPagesMetaAttributes: contentfulPageMetaAtrributes {
      register {
        title
        description
      }
      about {
        title
        description
      }
      blog {
        title
        description
      }
      earn {
        title
        description
      }
      dlinks {
        title
        description
      }
      faq {
        title
        description
      }
      index {
        title
        description
      }
      privacypolicy {
        title
        description
      }
      registry {
        title
        description
      }
      stats {
        title
        description
      }
      termsofuse {
        title
        description
      }
      useCases {
        title
        description
      }
      wrappedHns {
        title
        description
      }
      handshakeWhitepaper {
        title
        description
      }
    }

    allPagesImages: contentfulPageOpenGraphPreviewImages {
      logo {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      default {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      dlinks {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      earn {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      registry {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      wrappedHns {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
    }
  }
`;

export { usePageMetaAttributes };
