import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import { BlogPost } from '../components/blog/blogHome/BlogPost';
import { navSections } from '../components/navbar';
import { DisplayLarge, Huge } from '../components/text';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';
import SEO, { buildBlogPostSchema } from '../components/seo';

const query = graphql`
  fragment BlogPost on ContentfulBlogPost {
    id
    slug
    title
    createdAt
    updatedAt
    subtitle {
      subtitle
    }
    author {
      name
    }
  }
  query BlogPostsPageQuery {
    allContentfulBlogPost(limit: 1000) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
  }
`;

const BlogPosts = ({ data }) => {
  const meta = usePageMetaAttributes('blog');

  const sortedBlogPosts = data.allContentfulBlogPost.edges.sort(
    (a, b) => new Date(b.node.createdAt) - new Date(a.node.createdAt)
  );

  return (
    <Layout currentSection={navSections.RESOURCES}>
      <SEO
        {...meta}
        schemaMarkup={JSON.stringify({
          '@type': 'Blog',
          '@context': 'https://schema.org',
          url: 'https://namebase.io/blog',
          name: meta.title,
          about: meta.description,
          genre: ['Internet', 'Technology', 'Cryptocurrency'],
          blogPosts: sortedBlogPosts.map(blogPost => buildBlogPostSchema(blogPost.node, meta)),
        })}
      />
      <section>
        <Padding>
          <Header>
            <DisplayLarge as="h1" weight="medium">
              writing/
            </DisplayLarge>
            <Huge as="h2">A Collection of stories and resources from the Namebase team</Huge>
          </Header>
          <ol>
            {sortedBlogPosts.map(({ node: post }) => (
              <li key={post.id}>
                <Link to={`/blog/${post.slug}`}>
                  <BlogPost
                    title={post.title}
                    subtitle={post.subtitle.subtitle}
                    createdAt={post.createdAt}
                    updatedAt={post.updatedAt}
                  />
                </Link>
              </li>
            ))}
          </ol>
        </Padding>
      </section>
    </Layout>
  );
};

export { query };
export default BlogPosts;

const Padding = styled.div`
  width: 100vw;
  padding: 0 ${({ theme }) => theme.spacing(6)};

  @media ${props => props.theme.breakpoints.sm} {
    padding: 0 ${({ theme }) => theme.spacing(3)};
  }
  @media ${props => props.theme.breakpoints.xs} {
    padding: 0 ${({ theme }) => theme.spacing(2)};
  }
`;

const Header = styled.header`
  padding-top: ${({ theme }) => theme.spacing(12)};
  padding-bottom: ${({ theme }) => theme.spacing(8)};

  h2 {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
